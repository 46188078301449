
import { defineComponent } from 'vue';
import SimulationDataHelper from '@/helpers/SimulationDataHelper';
import globals from '../../../helpers/globals';
import FormButton from '../../../components/forms/Button.vue';
import FormCheckbox from '../../../components/forms/Checkbox.vue';
import FormInput from '../../../components/forms/Input.vue';
import AccountDataService from '../../../services/AccountDataService';
import FileDataService from '../../../services/FileDataService';

export default defineComponent({
  name: 'Register',
  mixins: [
    globals,
  ],
  components: {
    FormButton,
    FormInput,
    FormCheckbox,
  },
  data() {
    return {
      form: {
        serverError: null,
        inputs: {
          first_name: {
            value: null,
            rule: 'required|regex_alpha',
            error: null,
          } as {[key: string]: any},
          last_name: {
            value: null,
            rule: 'required|regex_alpha',
            error: null,
          } as {[key: string]: any},
          phone: {
            value: null,
            rule: 'required|phone:mobile',
            error: null,
          } as {[key: string]: any},
          email: {
            value: null,
            rule: 'required|email',
            error: null,
          } as {[key: string]: any},
          checkbox: {
            value: false,
            error: null,
            rule: 'required',
            conditions: 'J\'accepte <a href="/cgu" target="_blank" class="text-primary">les conditions générales d\'utilisation</a>.',
          } as {[key: string]: any},
        } as {[key: string]: any},
      },
    };
  },
  created() {
    const vm = this;

    /* LOAD DATA WHEN COMPONENT IS CREATED */
    this.$store.dispatch('isLoading', true);

    this.load((data: any) => {
      vm.$store.dispatch('setSelectedSimulation', data);

      if (this.isAuthenticated(vm.$store)) {
        const token = this.getAccessToken();

        FileDataService.create(token, vm.$route.params.simulation as string)
          .then((response: any) => {
            vm.$store.dispatch('setSelectedFile', response.data.data);
          });
      }

      vm.init();
    });
  },
  mounted() {
    /* Scroll page to top (case of mobile phones) */
    this.scrollToTop();
  },
  methods: {
    /* COMPONENT INITIALIZER */
    init() {
      /* FLAG STEP AS UNCOMPLETED */
      this.setStepAsCompleted(this, false);

      /* MOVE PROGRESS BAR TO VALUE */
      this.progress(this);

      /* SHOW SIDE AND NAV BAR IF USER FALLS DIRECTLY INTO CURRENT COMPONENT */
      this.$store.dispatch('toggleProgressBar', true);
      this.$store.dispatch('toggleSideBar', true);

      /* STOP LOADING */
      this.$store.dispatch('isLoading', false);
    },
    /* LOAD DATA THROUGH APIS */
    load(callback: any) {
      SimulationDataHelper.prepareComponentResult(
        this.$store,
        this.$route.params.simulation as string,
        callback,
      );
    },
    goToNextStep(event: any) {
      event.preventDefault();
      const vm = this;

      this.$store.dispatch('isLoading', true);

      // Load recaptcha token
      this.recaptcha(this)
        .then((resp: any) => {
          const form = {
            first_name: vm.form.inputs.first_name.value,
            last_name: vm.form.inputs.last_name.value,
            email: vm.form.inputs.email.value,
            phone: vm.form.inputs.phone.value,
            recaptcha: vm.$store.state.recaptcha,
          };

          AccountDataService.testRegister(vm.$store.state.selected.segment, this.$route.params.simulation as string, form)
            .then((response: any) => {
              vm.form.serverError = null;

              this.$store.dispatch('setDataIntoStep', { step: this.$options.name, data: form });
              this.goToNextScreen(this, {
                simulation: vm.$route.params.simulation,
              });
            })
            .catch((e: any) => {
              this.$store.dispatch('isLoading', false);

              if (e.response.status === 422) {
                Object.keys(e.response.data.data).forEach((name) => {
                  [vm.form.inputs[name].error] = e.response.data.data[name];
                });
              }
            });
        });
    },
    goToLogin(event: any) {
      const vm = this;

      event.preventDefault();

      let name = 'KYC';

      if (vm.$store.state.selected.segment.pid === 'pro') name = 'KYCProf';
      else if (vm.$store.state.selected.segment.pid === 'corp') name = 'KYCCorp';

      this.$store.dispatch('setAfterLogin', {
        name,
        params: {
          simulation: vm.$route.params.simulation as string,
        },
      });

      this.$router.push({
        name: 'Login',
        params: {
          simulation: this.$route.params.simulation as string,
        },
      });
    },
    parsePhoneNumber(fieldName: string, event: any) {
      const cleaned = this.form.inputs[fieldName].value.replace(/\D/g, '');

      /* if (event.keyCode === 8) cleaned = cleaned.slice(0, -1); */

      if (cleaned.length >= 9) this.form.inputs[fieldName].value = `${cleaned.substr(0, 4)} ${cleaned.substr(4, 2)} ${cleaned.substr(6, 2)} ${cleaned.substr(8, 2)}`;
      else if (cleaned.length >= 7) this.form.inputs[fieldName].value = `${cleaned.substr(0, 4)} ${cleaned.substr(4, 2)} ${cleaned.substr(6, 2)}`;
      else if (cleaned.length >= 5) this.form.inputs[fieldName].value = `${cleaned.substr(0, 4)} ${cleaned.substr(4, 2)}`;
      else this.form.inputs[fieldName].value = `${cleaned}`;
    },
    disabledSubmitButton() {
      let error = false;

      Object.values(this.form.inputs).every((element: any) => {
        if ((element.value === null && element.rule.includes('required')) || element.error !== null) {
          error = true;
          return false;
        }

        return true;
      });

      return error;
    },
  },
});
